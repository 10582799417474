import React, { useRef, useState, useEffect } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isIOS,
} from "react-device-detect";

// import { gsap } from "gsap"

import Layout from "../components/Layout";
// import Header from "../components/Header"
import Footer from "../components/Footer";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { Helmet } from "react-helmet";

import "../css/idea.scss";

// import IdeaView from "../images/idea/idea-view.png"
// import IdeaView from "../images/idea/idea-view.jpg"
import IdeaVideo from "../video/yasnaya-polyana.mp4";
// import IdeaVideo from "../video/test.mp4"
import btnPlaySm from "../images/idea/idea-btn-sm1.svg";
import btnPlayXl from "../images/idea/idea-btn-big1.svg";
import IdeaPoster from "../images/btn-play.svg";

import IdeaMan from "../images/idea/idea-man.png";
import IdeaCorrection from "../images/idea/idea-correction.png";
import IdeaSign from "../images/idea/idea-sign.svg";

function Idea() {
  const { t } = useTranslation();

  const breakpoints = useBreakpoint();
  var btnPlay;
  if (breakpoints.sm) {
    btnPlay = btnPlaySm;
  } else {
    btnPlay = btnPlayXl;
  }

  const vidRef = useRef(null);
  const refBtnPlay = useRef(null);
  const [isPlay, setIsPlay] = useState(false);
  const handlePlayVideo = () => {
    if (vidRef.current.paused) {
      vidRef.current.play();
      setIsPlay(true);
    } else {
      vidRef.current.pause();
      setIsPlay(false);
    }
  };

  return (
    <>
      <Layout>
        <Helmet>
          <title>{t("header")}</title>
        </Helmet>
        <main>
          <div className="page page-idea">
            <div className="page-title w-2/3 md:w-auto">
              <div className="page-title__name">{t("title")}</div>
            </div>

            <div className="page-header">
              <h1>{t("header")}</h1>
            </div>

            <div className="box__image box__image_view">
              <div className="idea-vid-wrapper" onClick={handlePlayVideo}>
                {/* <div className={`idea-poster ${isPlay ? "hidden" : ""}`}>
                  <img src={btnPlay} alt="" ref={refBtnPlay} />
                </div> */}
                {!isPlay && (
                  <div className="idea-poster">
                    <img src={btnPlay} alt="" ref={refBtnPlay} />
                  </div>
                )}
                {isIOS ? (
                  <video
                    ref={vidRef}
                    className="idea-video no-select"
                    playsInline
                    autoPlay
                    // muted
                    loop
                    preload="auto"
                    // poster={IdeaPoster}
                    // controls
                  >
                    <source src={IdeaVideo} />
                    {/* <source src={test} /> */}
                  </video>
                ) : (
                  <video
                    ref={vidRef}
                    className="idea-video no-select"
                    playsInline
                    // muted
                    loop
                    preload="auto"
                    // poster={IdeaPoster}
                    // controls
                  >
                    <source src={IdeaVideo} />
                    {/* <source src={test} /> */}
                  </video>
                )}
              </div>
            </div>

            {/* <div className="box__image box_idea-correction">
                <img
                  className="box__pic box__pic_correction"
                  src={IdeaCorrection}
                  alt=""
                />
              </div> */}
            <div className="row box-idea">
              <div className="col md:w-2/3">
                <div className="row">
                  <div className="col box__text box_idea-letter w-full md:w-3/4">
                    <div className="box__indent">{t("ideaLetter1")}</div>
                    <div className="box__indent">{t("ideaLetter2")}</div>
                    <div className="box__indent">{t("ideaLetter3")}</div>
                  </div>
                  <div className="col box__text w-1/3 md:w-1/2"></div>
                  <div className="col box__text box__sign w-2/3 md:w-1/2">
                    <div>{t("ideaFio")}</div>
                    <div className="box__image box__image_sign w-full">
                      <img
                        className="box__pic box__pic_sign"
                        src={IdeaSign}
                        alt="Подпись"
                      />
                    </div>
                  </div>
                  {/* <div className="col md:w-1/6"></div> */}
                </div>
              </div>
              <div className="col md:w-1/3">
                <div className="box__image box__image_man">
                  <img
                    className="box__pic box__pic_man"
                    src={IdeaMan}
                    alt="Илья Толстой"
                  />
                </div>
                <div className="box__caption box__caption_man w-2/3">
                  {t("ideaManCaption")}
                </div>
              </div>
            </div>

            <div className="page-header page-header_not-first">
              <h1>{t("principlesTitle")}</h1>
            </div>

            <div className="row box box_principle">
              <div className="col box__text md:w-1/2">
                {t("principlesText")}
              </div>
            </div>

            <div className="row box box_target idea_dots">
              <div className="col w-1/3 md:w-1/6 idea__number">1.</div>
              <div className="col box__text w-2/3 md:w-1/6">
                <span className="text-bottomline">
                  {t("principleTitle1Part1")}
                </span>
                <span className="text-bottomline text-bottomline_space">
                  &nbsp;
                </span>
                <span className="text-bottomline">
                  {t("principleTitle1Part2")}
                </span>
              </div>

              <div className="col w-1/3 md:w-1/6"></div>

              <div className="col box__text box__text-target-value w-2/3 md:w-1/2">
                {t("principleText1")}
              </div>
            </div>

            <div className="row box box_target idea_dots">
              <div className="col w-1/3 md:w-1/6 idea__number">2.</div>
              <div className="col box__text w-2/3 md:w-1/6">
                <span className="text-bottomline">
                  {t("principleTitle2Part1")}
                </span>
                <span className="text-bottomline text-bottomline_space md:hiddens">
                  &nbsp;
                </span>
                <span className="text-bottomline">
                  {t("principleTitle2Part2")}
                </span>
              </div>

              <div className="col w-1/3 md:w-1/6"></div>

              <div className="col box__text box__text-target-value w-2/3 md:w-1/2">
                {t("principleText2")}
              </div>
            </div>

            <div className="row box box_target box_target_last idea_dots">
              <div className="col w-1/3 md:w-1/6 idea__number">3.</div>
              <div className="col box__text w-2/3 md:w-1/6">
                <span className="text-bottomline">
                  {t("principleTitle3Part1")}
                </span>
                <span className="text-bottomline text-bottomline_space">
                  &nbsp;
                </span>
                <span className="text-bottomline">
                  {t("principleTitle3Part2")}
                </span>
              </div>

              <div className="col w-1/3 md:w-1/6"></div>

              <div className="col box__text box__text-target-value w-2/3 md:w-1/2">
                {t("principleText3")}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Layout>
    </>
  );
}

export default Idea;

{
  /* <div className="break"></div> */
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["idea", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
